const fr = {
    translation:{
        error_messages: {
            access_denied:"Accès refusé",
            access_denied_detail:"Nous n'avons pas trouvé la page que vous recherchiez.",
        },
        creditCardForm: {
            currency_name: "Devise",
            card_number: "Numéro de carte",
            card_expiry: "Date d’expiration(MM/AAAA)",
            card_holder_name: "Nom figurant sur la carte",
            card_holder_nickname: "Pseudonyme de la carte",
            pay_accepting: "Payer les fournisseurs qui acceptent ce mode de paiement",
            pay_accepting_detail: "Utiliser une carte de crédit de pour payer les fournisseurs qui acceptent les paiements par carte de crédit",
            pay_non_accepting: "Payer les fournisseurs qui n’acceptent pas ce mode de paiement",
            pay_non_accepting_detail: "Utiliser votre carte de crédit pour faire des paiements à des fournisseurs qui n’acceptent pas les paiements par carte de crédit",
            personal_detail_formgroup: "Renseignements personnels",
            personal_formgroup_description: "Pour que la validation soit réussie, saisissez votre adresse exactement telle qu'elle apparaît sur votre relevé de compte",
            street_number: "Numéro municipal",
            street_name: "Nom de la rue",
            city_name: "Ville",
            province_name: "Province",
            postal_code_name: "Code postal",
            mobile_number_name: "Numéro de cellulaire",
            email_address_name: "Adresse courriel",
            submit_button: "SOUMETTRE",
            cancel_button: "ANNULER"

        },
        validationErrors:{
            require_label: "Obligatoire",
            card_invalid_label: "Numéro de carte {{cardType}} invalide",
            email_invalid_label: "Adresse courriel invalide",
            expiry_invalid_label: "Date d’expiration de la carte de crédit non valide",
            postalcode_invalid_label: "Veuillez entrer le code postal en majuscules. Par exemple : M1M1M1",
            field_max_label: "Ne doit pas dépasser {{max}} caractères",
            field_min_label: "Doit compter au moins {{min}} caractères",
            phone_invalid_label: "Numéro de cellulaire invalide",
            suppliertype_invalid_label: "Sélectionnez un type de fournisseur",
        },
        provincesList:{
            none: "Aucun",
            AB:"Alberta",
            BC:"Colombie-Britannique",
            MB:"Manitoba",
            NB:"Nouveau-Brunswick",
            NL:"Terre-Neuve-et-Labrador",
            NT:"Territoires-du-Nord-Ouest",
            NS:"Nouvelle-Écosse",
            NU:"Nunavut",
            ON:"Ontario",
            PE:"Île-du-Prince-Édouard",
            QC:"Québec",
            SK:"Saskatchewan",
            YT:"Yukon"
        }
    }
};
export default fr;